import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div className="contact section" id="Kapcsolat">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Kapcsolat</h2>
          </div>
          <form
            action="https://formspree.io/f/xpzkyajj"
            name="contact"
            method="POST"
            data-netlify="true"
          >
            <div>
              <label>
                Név: <input type="text" name="name" required />
              </label>
            </div>
            <div>
              <label>
                Email: <input type="email" name="email" required />
              </label>
            </div>
            <div>
              <label>
                Üzenet: <textarea name="message" required></textarea>
              </label>
            </div>
            <div>
              <button type="submit">Küldés</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
